exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alumin-js": () => import("./../../../src/pages/Alumin.js" /* webpackChunkName: "component---src-pages-alumin-js" */),
  "component---src-pages-bimetal-js": () => import("./../../../src/pages/Bimetal.js" /* webpackChunkName: "component---src-pages-bimetal-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-panelnie-js": () => import("./../../../src/pages/Panelnie.js" /* webpackChunkName: "component---src-pages-panelnie-js" */),
  "component---src-pages-photo-video-js": () => import("./../../../src/pages/PhotoVideo.js" /* webpackChunkName: "component---src-pages-photo-video-js" */),
  "component---src-pages-radiator-main-js": () => import("./../../../src/pages/RadiatorMain.js" /* webpackChunkName: "component---src-pages-radiator-main-js" */)
}

